.fc-button-primary {
	background-color: #cd9988;
	border-radius: 2px;
	border: none;
	box-shadow: 0px 3px 5px -1px rgba(205, 153, 136, 0.2),
		0px 6px 10px 0px rgba(205, 153, 136, 0.14), 0px 1px 18px 0px rgba(205, 153, 136, 0.12);
	text-transform: uppercase;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fc-button-primary:hover {
	background-color: #e8ad9c;
}

.fc-button-primary:focus,
.fc-button-primary:active:focus,
.fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus {
	background-color: #987466;
	box-shadow: none;
}

.fc-button-primary:disabled {
	background-color: #987466;
}

.fc-unthemed td.fc-today {
	background-color: #eaeaea;
}

.fc-event {
	background-color: #cd9988;
	border-color: #cd9988;
	border: none;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fc-event,
.fc-event:hover {
	border-radius: 2px;
	padding: 5px;
	box-shadow: 0px 3px 5px -1px rgba(205, 153, 136, 0.2),
		0px 6px 10px 0px rgba(205, 153, 136, 0.14), 0px 1px 18px 0px rgba(205, 153, 136, 0.12);
	cursor: pointer;
}

.fc-event:hover {
	background-color: #e8ad9c;
}

.fc-event:focus {
	background-color: #E2AD94;
}

.noTime > .fc-content > .fc-time  {
	display: none;
}

@media screen and (max-width: 640px) {
	.fc-toolbar h2 {
		font-size: 1.3em;
		margin: 0;
	}
}

.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
	white-space: normal;
}
